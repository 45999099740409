var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.users_headers,"items":_vm.billing,"options":_vm.options,"server-items-length":_vm.total_billing,"multi-sort":false,"item-key":"id","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText,"loading":_vm.loading_billing,"loading-text":"Загрузка... Подождите","item-class":_vm.row_class,"items-per-page":25,"footer-props":{
          'items-per-page-options': [15, 25, 50, 100, -1],
        }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.charge_info},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-text-field',{attrs:{"hide-details":"","label":"Поиск по ID","prepend-icon":"mdi-magnify","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.client",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.client != null ? "+" + item.client : "")+" ")]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.payment_status(item.status))+" ")]}},{key:"item.reason",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.payment_reason(item))+" ")]}},{key:"item.message",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.bank_message(item))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }