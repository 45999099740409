<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="users_headers"
          :items="billing"
          :options.sync="options"
          :server-items-length="total_billing"
          :multi-sort="false"
          item-key="id"
          :search="search"
          :custom-filter="filterOnlyCapsText"
          :loading="loading_billing"
          loading-text="Загрузка... Подождите"
          @click:row="charge_info"
          :item-class="row_class"
          :items-per-page="25"
          :footer-props="{
            'items-per-page-options': [15, 25, 50, 100, -1],
          }"
        >
          <template v-slot:top>
            <v-toolbar dense flat>
              <v-text-field
                v-model="search"
                hide-details
                label="Поиск по ID"
                prepend-icon="mdi-magnify"
                single-line
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item.client="{ item }">
            {{ item.client != null ? "+" + item.client : "" }}
          </template>
          <template v-slot:item.status="{ item }">
            {{ payment_status(item.status) }}
          </template>
          <template v-slot:item.reason="{ item }">
            {{ payment_reason(item) }}
          </template>
          <template v-slot:item.message="{ item }">
            {{ bank_message(item) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "List_billing",
  data() {
    return {
      search: "",
      options: {
        sortBy: ["id"],
        sortDesc: [true],
      },
    };
  },
  created() {
    if (localStorage.getItem("billing_filters")) {
      let params = JSON.parse(localStorage.getItem("billing_filters"));
      this.search = params.request_id;
      this.options.sortBy = params.sortBy ? params.sortBy : ["id"];
      this.options.sortDesc = params.sortDesc ? params.sortDesc : [false];
      this.options.page = params.page ? params.page : 1;
    }
    this.load_billing();
  },
  watch: {
    options: {
      handler() {
        this.load_billing();
      },
      deep: true,
    },
    params: {
      handler() {
        this.load_billing();
      },
      deep: true,
    },
  },
  computed: {
    params() {
      return {
        ...this.options,
        request_id: this.search,
      };
    },

    loading_billing() {
      return this.$store.getters.LOADING_BILLING;
    },
    total_billing() {
      return this.$store.getters.TOTAL_BILLING;
    },
    users_headers() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "ID платежа",
          align: "start",
          sortable: true,
          value: "request_id",
        },
        {
          text: "Причина",
          align: "start",
          sortable: true,
          value: "reason",
        },
        {
          text: "Client",
          align: "start",
          sortable: true,
          value: "client",
        },
        {
          text: "Сумма",
          align: "start",
          sortable: true,
          value: "amount",
        },
        {
          text: "Статус",
          align: "start",
          sortable: true,
          value: "status",
        },
        {
          text: "Bank message",
          align: "start",
          sortable: true,
          value: "message",
        },
        {
          text: "Создан",
          align: "start",
          sortable: true,
          value: "created_at",
        },
      ];
    },
    billing() {
      return this.$store.getters.BILLING;
    },
  },
  methods: {
    load_billing() {
      this.$store.dispatch("list_billing", this.params);
    },
    charge_info(item) {
      if (item.charging_id == null) {
        return;
      }
      this.$router.push({
        name: "charging_info",
        params: { charge_id: item.charging_id },
      });
    },
    row_class(item) {
      if (item.charging_id == null) return;

      return "clickable";
    },
    payment_reason(item) {
      if (item.charging_id == null) return "Подключение карты";
      return "Оплата зарядки";
    },
    bank_message(item) {
      if (item.response_raw == null) return;
      if ("AcsUrl" in item.response_raw.Model) return "3ds авторизация";
      if ("CardHolderMessage" in item.response_raw.Model)
        return item.response_raw.Model.CardHolderMessage;
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search) !== -1
      );
    },
    payment_status(status) {
      switch (status) {
        case "Payment approved":
          return "Одобрено";
        case "Payment declined":
          return "Отклонен";
        case "Payment created":
          return "Создан";
        case "Payment refunded":
          return "Возвращен";
      }
    },
  },
};
</script>

<style scoped></style>
